import React from "react"
import { sbEditable } from '@storyblok/storyblok-editable'
import Hero from "../sections/Home/Hero"
import TitleWithText from "../sections/Home/TitleWithText"
import CtaBox from "../sections/Generic/CtaBox"
import ImageGrid from "../sections/Generic/ImageGrid"
import ProjectInfo from "../sections/Projects/ProjectInfo"
import BasicCopy from "../sections/Home/BasicCopy"

const Components = {
  "hero": Hero,
  "text_with_title": TitleWithText,
  "cta_block": CtaBox,
  "image_grid": ImageGrid,
  "project_header": ProjectInfo,
  "basic_copy": BasicCopy
}
 
const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<div {...sbEditable(blok)} className='h-full w-full max-w-[100vh] lg:max-w-[unset]'><Component blok={blok} /></div>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}
 
export default DynamicComponent
