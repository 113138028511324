import React, { useEffect, useRef } from "react";
import Div100vh from "react-div-100vh";
import { Fade } from "react-awesome-reveal";
import ResImg from "../../components/resImg";
import Overlay from "../../components/trust/Overlay";
import ResVideo from "../../components/resVideo";
import { useInView } from 'react-intersection-observer';


const Cheveron = (props) => {
  return(
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 152.07 223.12"
      {...props}
    >
      <defs>
        <style>{".cls-1{fill:white}"}</style>
      </defs>
      <path
        className="cls-1"
        d="M0 120V0h38.71v117.79c0 27.38 11.89 37.88 37.6 37.88s37.05-10.5 37.05-37.88V0h38.71v120c0 47.56-32.91 70.51-75.76 70.51S0 167.56 0 120z"
      />
      <path className="cls-1" d="M0 208.94H151.79V223.12H0z" />
    </svg>
  )
}

const Hero = ({ blok }) => {
  const title = blok.title;

  const hasVideo = blok.desktop_video?.filename != '';

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    let timeout;

    if (inView) {
      // in 20 seconds, scroll down to the next section if the user hasn't scrolled
      timeout = setTimeout(() => {
        if (window.scrollY === 0) {
          window.scrollTo({
            top: window.innerHeight * 0.5,
            behavior: 'smooth',
          });
        }
      }
      , 20000);
      
    }

    // cancel the timeout if unmounted
    return () => clearTimeout(timeout);
  }, [inView]);






  return (
    <>
      <section className="relative bg-black h-screen w-screen text-white text-center" ref={ref}>
        {blok.desktop_image && hasVideo == false && (
          <ResImg mobile={blok.mobile_image} desktop={blok.desktop_image} />
        )}

        {hasVideo == true && (
          <ResVideo mobilePoster={blok.mobile_image} desktopPoster={blok.desktop_image} desktopVideo={blok.desktop_video?.filename} mobileVideo={blok.mobile_video?.filename}/>
        )}



        {blok.overlay && (<Overlay/>)}

        <Div100vh className="flex items-center absolute inset-0 flex-col justify-between transition-all duration-100">
            <div></div>

            <p className="p uppercase font-bold tracking-wider">{title}</p>

            {blok.show_chevron ? (<div className="pb-[45px]">

                <Cheveron className="w-[30px] h-[30px]"/>


              </div>) : (<div></div>)}
        </Div100vh>
      </section>
    </>
  );
};

export default Hero;
