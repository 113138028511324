import React, { useState, Fragment } from "react";
import Link from "../../utils/link";
import ResImg from "../resImg";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";

const Overlay = () => {
  return (
    <div className="w-full h-full absolute inset-0 bg-inherit opacity-[0.55] bg-black"></div>
  );
};

function VideoPlay(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79 79"
      {...props}
    >
      <path
        d="M79 39.5C79 61.315 61.315 79 39.5 79S0 61.315 0 39.5 17.685 0 39.5 0 79 17.685 79 39.5z"
        fill="#D9D9D9"
        fillOpacity={0.5}
      />
      <path d="M56 39.5L29.75 54.655v-30.31L56 39.5z" fill="#fff" />
    </svg>
  );
}

const ProjectBox = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const item = props.item;

  const link = item.link;
  const title = item.title;
  const mobile_image = item.mobile_image;
  const desktop_image = item.desktop_image;
  const overlay = item.overlay || false;

  const index = props.index;

  const isFirstOrEveryThird = (index) => {
    return index === 0 || index % 3 === 0;
  };
  

  const desktop_width = props.filterOpen ? isFirstOrEveryThird(index) ? `4-4` : `2-4`: item.desktop_width || "4-4";

  // has link

  const isClickable = link.cached_url != "";

  const isProject = link.cached_url.includes("projects");

  const video = item.video?.filename;

  const isVideo = video != "";

  return (
    <Fade
      triggerOnce
      className={`relative bg-slate-100 group ${
        desktop_width == "1-4" ? "lg:col-span-1" : null
      } ${desktop_width == "2-4" ? "lg:col-span-2" : null} ${
        desktop_width == "3-4" ? "lg:col-span-3" : null
      } ${desktop_width == "4-4" ? "lg:col-span-4" : null}
        ${desktop_width == "" ? "lg:col-span-4" : null}
      `}
      
    >
      <div>
        <div className="overflow-hidden lg:h-[760px] aspect-[330/520] lg:aspect-auto">
          <div
            className={`${
              isClickable
                ? `group-hover:scale-[101%] transition-transform duration-1000`
                : ``
            } w-full h-full`}
          >
            <ResImg mobile={mobile_image} desktop={desktop_image} />
          </div>
        </div>
        {overlay && <Overlay />}

        {title != undefined && title != "" && (
          <div
            className={`w-full h-full flex items-center justify-center absolute inset-0 duration-600 transition-opacity ${
              isProject ? `lg:group-hover:opacity-0` : ``
            }`}
          >
            <p className="text-white p font-regular">{title}</p>
          </div>
        )}

        {isProject && (
          <div className="w-full h-full absolute inset-0 items-center justify-center hidden lg:flex px-theme opacity-0 group-hover:opacity-100 duration-600 transition-opacity">
            <button className="button bg-transparent text-white border border-white min-w-[33%] text-center border-button">
              <span>View Project</span>
            </button>
          </div>
        )}

        {link != undefined && isClickable == true && (
          <Link className="w-full h-full inset-0 absolute" to={link.cached_url}>
            <span className="u-visually-hidden">View {title}</span>
          </Link>
        )}

        {isVideo && (
          <>
            <div className="w-full h-full absolute inset-0 items-center justify-center flex px-theme">
              <button
                className="duration-600 transition-transform hover:scale-110"
                onClick={() => setModalOpen(true)}
              >
                <VideoPlay className="w-[80px] h-[80px]" />
              </button>
            </div>
            <Transition.Root show={modalOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-600"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-600"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-300"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden  bg-black transition-all max-w-7xl px-theme w-screen aspect-video">
                        <video
                          className="w-full aspect-video h-full"
                          controls
                          src={video}
                          autoPlay
                        />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        )}
      </div>
    </Fade>
  );
};

export default ProjectBox;
