import React from "react";
import { Fade } from "react-awesome-reveal";
import { render } from "storyblok-rich-text-react-renderer";

const disableHeader = true

const ProjectInfo = (props) => {
  const blok = props.blok;

  const blocks = blok.collaborators;

  const title = blok.project_name;

  return (
    <section className={`${disableHeader ? `pt-[11vw] lg:pt-[135px]` : `padding-y-about`}`}>
      <div className={`container-theme lg:grid-cols-2 lg:gap-x-[9.58vw] ${disableHeader ? `hidden` : `grid`}`}>
        {disableHeader == false ? (
        <Fade cascade triggerOnce>
          <div>
            <h2
              className="p font-bold"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
          </div>

          <div className="h-full flex flex-col justify-center relative pt-[35px] lg:pt-0 lg:order-first">
            <div className="p prose text-black">{render(blok.copy)}</div>
            {blocks.map((item, index) => {
              const listItems = item.blocks;

              return (
                <div className="pt-[65px]" key={`collaborator-${index}`}>
                  <h3 className="p font-bold pb-[25px]">{item.title}</h3>
                  <ul className="space-y-[25px]">
                    {listItems.map((collaborator, index) => {
                      return (
                        <li key={`collaborator-${index}`} className="p">
                          {collaborator.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </Fade>
        ) : 
          null
        }
      </div>
    </section>
  );
};

export default ProjectInfo;
