import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Fade } from "react-awesome-reveal";
import { render } from "storyblok-rich-text-react-renderer";

const TitleWithText = (props) => {

  const blok = props.blok

  const title = blok.title
  const copy = "We build. We fit-out. We project manage.<br/><br/> With over 30 years combined industry experience, our team continues to provide a seamless service, with our clients as our primary focus. We specialise in retail, hospitality, corporate, base-build & office – we’re here to bring your vision to life.<br/><br/> We like to keep it simple. You. Us. It all starts with Trust."
  
  
  
  return (
    <section className="padding-y-about">
      <div className="container-theme grid lg:grid-cols-2 lg:gap-x-[9.58vw]">
    <Fade cascade triggerOnce>

      <div>
      <h2
          className="h2 font-bold uppercase"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>

      </div>

      <div className="h-full flex flex-col justify-center relative pt-[35px] lg:pt-0 lg:order-first" >
        <div
          className="p prose text-black"
        >
          {render(blok.copy)}
        </div>
      </div>
      </Fade>
      </div>
    </section>
  );
};

export default TitleWithText;
