import React from "react";
import { Fade } from "react-awesome-reveal";
import { render } from "storyblok-rich-text-react-renderer";

const BasicCopy = (props) => {

  const blok = props.blok
  
  
  return (
    <section className="padding-y-about">
      <div className="container-theme grid lg:grid-cols-1 gap-y-8 lg:gap-y-16">
    <Fade cascade triggerOnce>

      <div>
      <h2
          className="h2 font-bold uppercase"
          dangerouslySetInnerHTML={{ __html: blok.title }}
        ></h2>

      </div>

      <div className="h-full flex flex-col justify-center relative" >
        <div
          className="p prose text-black !max-w-full"
        >
          {render(blok.copy)}
        </div>
      </div>
      </Fade>
      </div>
    </section>
  );
};

export default BasicCopy;
