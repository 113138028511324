import React, {useRef} from "react";
import Styled from "styled-components";
import Footer from "./trust/Footer";
import Header from "./trust/Header";

const LayoutWrapper = Styled.div`


`;

function Layout(props) {
  const containerRef = useRef(null);

  return (
    

    <LayoutWrapper data-scroll-container ref={containerRef} {...props}>
      <main className="min-h-[100vh]">
        
        <Header/>
        {props.children}
        <Footer/>

      </main>
    </LayoutWrapper>

  );
}

export default Layout;
