import React from "react"

const Overlay = () => {
    return(
        <div className='w-full h-full absolute inset-0 bg-inherit opacity-[0.55] bg-black'>

        </div>
    )
}

export default Overlay