import React, {useState, useRef} from "react"

const ContactForm = () => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const formRef = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        let myForm = formRef.current
        let formData = new FormData(myForm);
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => console.log("Form successfully submitted"))
          .catch((error) => alert(error));
      
        setFormSubmitted(true)
    }


    return(
        <div className="relative text-footer c-form">
            <form className={`transition-opacity duration-theme flex flex-col space-y-[5%] ${formSubmitted ? `opacity-0` : `opacity-100`}`} onSubmit={handleSubmit} ref={formRef} data-netlify="true" method="post" name="contact">
                <input type="hidden" name="form-name" value="contact" />


                <input className="input" placeholder="Name*" required name="name"/>
                <input className="input" placeholder="Number" name="phone" type="tel"/>
                <input className="input" placeholder="Email*" required name="email" type="email"/>
                <textarea className="input" placeholder="Message (Optional)" rows={5} name="message"/>
                <button className="button border border-white w-fit" type="submit">
                    Send
                    </button>
            </form>
            <div className={`absolute inset-0 transition-opacity duration-theme ${!formSubmitted ? `opacity-0 pointer-events-none` : `opacity-100`}`}>
                <p className="p text-white">
                    Thankyou for your enquiry,<br/>
                    {`we'll be in touch shortly.`}
                </p>
            </div>
        </div>
    )
}

export default ContactForm