import React from "react";

const CloseCross = (props) => {
    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="7.35 6.93 16 16.71"
        {...props}
      >
        <path stroke="#fff" d="M7.85355 7.43072L22.8536 22.4307" />
        <path stroke="#fff" d="M22.8536 8.13782L7.85355 23.1378" />
      </svg>
    );
  };
  

export default CloseCross