import React, { useState, useEffect } from "react";
import ProjectBox from "../../components/trust/ProjectBox";
import { Fade } from "react-awesome-reveal";
import { Listbox, Transition } from "@headlessui/react";

function Cheveron(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.5 -0.06 26 13.78"
      {...props}
    >
      <path d="M26 1L13.783 13.217 1 .435" stroke="#000" />
    </svg>
  );
}

const ImageGrid = (props) => {
  // fill empty array with 3 items

  const blok = props.blok;

  const projects = blok.blocks;

  const enable_filter = blok.enable_filter;

  const limit = blok.limit == 0 ? projects.length : blok.limit;

  const [currentLimit, setCurrentLimit] = useState(limit);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Extract filter tags from projects

  let filter_tags = [
    {
      id: 0,
      name: blok.filter_all_title,
      value: "All",
    },
  ];

  projects.forEach((project, index) => {
    // Check if project array contains filter tag already
    if (!filter_tags.some((tag) => tag.name === project.filter_tag)) {
      filter_tags.push({
        id: index + 1,
        name: project.filter_tag,
        value: project.filter_tag,
      });
    }
  });

  const [filter, setFilter] = useState(filter_tags[0]);

  const [projectItems, setProjectItems] = useState(projects);

  // Update projects if changes are detected

  useEffect(() => {
    setProjectItems(blok.blocks);

  }, [JSON.stringify(blok.blocks)]);

  const handleFilter = (filter) => {
    setFilter(filter);
    if (filter.value === "All") {
      setProjectItems(projects);
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
      setProjectItems(
        projects.filter((project) => project.filter_tag === filter.value)
      );
      setCurrentLimit(limit);

    }
  };

  return (
    <>
      {enable_filter && (
        <section className="container-theme pb-[55px] lg:pb-[100px] relative z-[5]">
          <div className="lg:col-span-4 lg:flex items-center justify-center pt-[72px] lg:pt-[125px] relative w-full">
            <div className="relative">
              <Listbox value={filter} onChange={handleFilter}>
                {({ open }) => (
                  <>
                    <Listbox.Button
                      className={
                        "p  border-b border-black w-full lg:w-[560px] pb-2 lg:pb-1"
                      }
                    >
                      {filter.name}

                      <Cheveron
                        className={`absolute right-0 top-0 h-5 w-5 mt-1 mr-2 duration-300 transition-transform ${
                          open ? `rotate-180` : ``
                        }`}
                      />
                    </Listbox.Button>
                    <Transition
                      enter="transition duration-300 ease-out"
                      enterFrom="transform scale-y-0 opacity-0"
                      enterTo="transform scale-y-100 opacity-100"
                      leave="transition duration-200 ease-out"
                      leaveFrom="transform scale-y-100 opacity-100"
                      leaveTo="transform scale-y-0 opacity-0"
                    >
                      <Listbox.Options className="w-full absolute top-[100%] py-4 text-center space-y-2 bg-white border-x border-b border-black ">
                        {filter_tags.map((item) => (
                          <Listbox.Option
                            key={item.id}
                            value={item}
                            className="p cursor-pointer"
                          >
                            {item.name == blok.filter_all_title
                              ? `All`
                              : item.name}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </section>
      )}
      <section className="grid lg:grid-cols-4 project-gap container-theme" key={limit}>
        {projectItems.map((item, index) => {
          // Limit number of projects
          if (index < currentLimit) {
            return (
              <ProjectBox key={`grid-${blok._uid}-item-${index}`} item={item} filterOpen={isFilterOpen} index={index}/>
            );
          }
        })}
      </section>
      {currentLimit < projectItems.length && (
        <section className="pt-[65px] lg:pt-[97px]">
          <div className="container-theme flex items-center justify-center ">
            <button
              className="button outlined"
              type="button"
              onClick={() => setCurrentLimit(parseInt(currentLimit) + parseInt(limit))}
            >
              <span>Load More</span>
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default ImageGrid;
