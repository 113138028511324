import React from "react";
import Link from "../../utils/link";

const CtaBox = (props) => {

    const blok = props.blok
    const title = blok.cta
    const link = blok.link

    return(
        <section className="py-[68px] lg:pt-[95px] lg:pb-[80px]">
            <div className="container-theme flex items-center justify-center ">
                
                <Link className="button outlined" to={link.cached_url}>

                    <span>{title}</span>

                </Link>

            </div>
        </section>
    )
}

export default CtaBox