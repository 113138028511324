import React from "react";
import Link from "../../utils/link";
import Footer from "./Footer";

const MenuItems = [
  {
    title: "Projects",
    link: "/projects",
  },
  {
    title: "Contact",
  },
];

const MobileMenu = (props) => {
  const toggleContact = () => {
    props.set(false);
    props.setModalOpen(true);
  };

  const closeMenu = () => {
    props.set(false);
  };


  const menuItems = props.menuItems.map((item, index) => {
    return (
      <li
        key={index}
        className="text-white font-bold text-[16px] uppercase tracking-wider"
      >
        {item.title != "Contact" ? (
          <Link to={item.link.cached_url} onClick={closeMenu}>{item.title}</Link>
        ) : (
          <button
            className="font-bold text-[16px] uppercase tracking-wider"
            onClick={toggleContact}
          >
            {item.title}
          </button>
        )}
      </li>
    );
  });
  return (
    <div
      className={`w-full h-full overflow-y-scroll fixed top-0 left-0 z-20 bg-black lg:hidden transition-opacity duration-300 ${
        props.open ? `opacity-100` : `opacity-0 pointer-events-none`
      }`}
    >
      <div className="h-screen flex justify-center items-center w-screen">
        <nav>
          <ul className="flex flex-col space-y-[28px] text-center justify-center w-full">
            {menuItems}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
