import React from "react";
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const FluidImage = (props) => {

    const { image, ...rest } = props

    const blur = props.blur || true



    // Get image file name

    const filename = image.filename

    // Strip https://a.storyblok.com from the image url

    const url = filename.replace('https://a.storyblok.com/f/', '')


    // Split by / to get image resolution and file name

    const parts = url.split('/')


    const resolutionString = parts[1]

    const resolution = resolutionString.split('x')

    const width = resolution[0]

    const height = resolution[1]


    const fluidProps = getFluidGatsbyImage(image.filename, {
        maxWidth: width,
        quality: props.quality || 95,
    })


    return (
        <Img fluid={fluidProps} placeholderClassName={`${blur ? "placeholder-img": ""}`} fadeIn={blur} draggable={false} alt={image.alt} loading={props.critical ? `eager` : `lazy`}  {...rest} />
    )
}

export default FluidImage